import React, {useState} from 'react'
import AddProductModal from '../../modals/AddProductModal/AddProductModal';
import AddNewModal from '../../modals/AddNewModal/AddNewModal';
import AddPictureModal from '../../modals/AddPictureModal/AddPictureModal';

import MessageTable from './MessageTable';
import { Button } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'

function Admin() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onLogout = () => {
    auth.signOut()
    navigate('/')
  }
  
  return (
   <>
   <div className="ftco-section">
    <div className='container'>
      <h2>Admin</h2>
      <Button onClick={onLogout} className='danger mb-3' >Log Out</Button>
    <div className='row mb-3'>
      <AddProductModal/>
      </div>
      {/* <div className='row mb-3'>
        <ProductTable/>
      </div> */}
      <div className='row mb-3'>
      <AddNewModal/>
      </div>
      {/* <div className='row mb-3'>
        <NewsTable/>
      </div> */}
      <div className='row mb-3'>
      <AddPictureModal/>
      </div>
      {/* <div className='row mb-3'>
        <PicturesTable/>
      </div> */}

      <div className='row mb-3'>
      <h3>Messages</h3>
      </div>
      <div className='row mb-3'>
        <MessageTable/>
      </div>
    </div>
   </div>
   </>
  )
}

export default Admin