// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getAuth} from 'firebase/auth'
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4br_2OdLUUTw2VKEH1rXgMJ9UFDTDyGw",
  authDomain: "olives-proj.firebaseapp.com",
  projectId: "olives-proj",
  storageBucket: "olives-proj.appspot.com",
  messagingSenderId: "912885103409",
  appId: "1:912885103409:web:754ef4ed15fd53be0d8e10"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore()
export {db, firebaseConfig} ;