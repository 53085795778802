import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import News from "./pages/News/News";
import Contact from "./pages/Contact/Contact";
import Products from "./pages/Products/Products";
import SingleProduct from "./pages/SingleProduct/SingleProduct";
import SingleNew from "./pages/SingleNew/SingleNew";
import Login from "./pages/Login/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import AdminPage from "./pages/Admin/Admins";
import { Admin, Resource, ListGuesser } from "react-admin";
import Gallery from "./pages/Gallery/Gallery";
function App() {
  return (
    <div className="goto-here">

      <Router>
      
      <Navbar/>
            <Routes>        
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/about" element={<About/>}/>
              <Route exact path="/news" element={<News/>}/>
              
              <Route exact path="/gallery" element={<Gallery/>}/>
             
              <Route exact path="/contact" element={<Contact/>}/>
              <Route exact path="/products" element={<Products/>}/>
              <Route path="/products/:id" element={<SingleProduct/>}/>
              <Route path="news/:id" element={<SingleNew/>}/>
              <Route exact path="/login" element={<Login/>}/>
              {/* <Route exact path="/admin" element={<AdminPage/>}/> */}
              

              <Route  path="/admin" element={<PrivateRoute/>}>
                  <Route exact path="/admin" element={<AdminPage/>}/>
              </Route>
              
            </Routes>
            <Footer/>
      </Router>
     
    </div>

  );
}

export default App;
