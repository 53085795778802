import React from 'react'
import {Navigate, Outlet } from 'react-router-dom'
import { useAuthStatus } from '../../hooks/useAuthStatus'
import Spinner from '../Spinner/Spinner'
const PrivateRoute = () => {

    const {loggedIn, checkingStatus} = useAuthStatus()

    if(checkingStatus){
        return <Spinner/>
    }
    console.log("logged in? ",loggedIn)
  return (
     loggedIn? <Outlet/> : <Navigate to="/login" />  
  )
}

export default PrivateRoute