import React from 'react'
import AboutBanner from '../../components/about-banner/AboutBanner'
import ProductDetail from '../../components/product-detail/ProductDetail'
function SingleProduct() {
  return (
    <>
        <AboutBanner pageName={"Product"}/>
        <ProductDetail/>
    </>
  )
}

export default SingleProduct