import React, {useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAuth } from 'firebase/auth'
import {collection, getDocs, query, deleteDoc,  doc} from 'firebase/firestore'
import {db} from '../../firebase.config'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from '../../components/Spinner/Spinner';
function ProductTable({flag}) {
    const auth = getAuth()
    const [products, setProducts] = useState(null)
    const [loading, setLoading] = useState(true)


    const onDelete = async (productId) => {
        if (window.confirm('Are you sure you want to delete?')) {
          await deleteDoc(doc(db, 'products', productId))
          const updatedProducts = products.filter(
            (product) => product.id !== productId
          )
          setProducts(updatedProducts)
          toast.success("Product Deleted!", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }



    useEffect(()=>{ 
        const fetchProducts = async () =>{
            try {
                const productsRef = collection(db, 'products')
                const q = query(productsRef
                   )

                const querySnap = await getDocs(q)
                let products = []
                querySnap.forEach((doc)=>{
                   return products.push({
                       id: doc.id,
                       data: doc.data()
                   })
                })
                setProducts(products)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        fetchProducts()
    },[flag]) 


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    
  return (
    <>
    {loading ? (<Spinner/> ): products && products.length > 0 ?
					 (<>
            <ToastContainer />
						<TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Product</StyledTableCell>
            <StyledTableCell align="right">Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <StyledTableRow key={product.id}>
              <StyledTableCell component="th" scope="row">
                {product.data.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                <button className='btn btn-danger' onClick={() => onDelete(product.id)}>Delete</button>
	    							</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
					 </>)
					: (<p>No products to show</p>)
					}
     
    </>
  )
}

export default ProductTable