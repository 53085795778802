import React, {useEffect, useState} from 'react'
import AboutBanner from '../../components/about-banner/AboutBanner'
import GalleryItem from './GalleryItem';
import { getAuth } from 'firebase/auth'
import {collection, getDocs, query} from 'firebase/firestore'
import {db} from '../../firebase.config'
import Spinner from '../../components/Spinner/Spinner';
function Gallery() {
  
  const auth = getAuth()
  const [pictures, setPictures] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
		const fetchPictures = async () =>{
			try {
				const newsRef = collection(db, 'gallery')
				const q = query(newsRef)

				const querySnap = await getDocs(q)
				let pics = []
				querySnap.forEach((doc)=>{
				   return pics.push({
					   id: doc.id,
					   data: doc.data()
				   })
				})
				setPictures(pics)
				setLoading(false)
			} catch (error) {
				console.log(error)
			}
		}
		fetchPictures()
	},[])


  return (
    <>
    <AboutBanner pageName={"Gallery"}/>
    <section className="ftco-section ftco-category ftco-no-pt mt-3">
			<div className="container">
				<div className="row">
        {loading ? (<Spinner/> ): pictures &&  pictures.length > 0 ?
					 (<>
						{ pictures.map((pic)=>(
							<GalleryItem key={pic.id} picture={pic.data}/>
						))}
					 </>)
					: (<p>No Pictures to show</p>)
					}
                 

				</div>
			</div>
		</section>
    </>
  )
}

export default Gallery