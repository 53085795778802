import React, {useEffect, useState} from 'react'
import { useLocation, Link } from 'react-router-dom';
import { getAuth} from 'firebase/auth'
import Spinner from '../Spinner/Spinner'
function Navbar() {
    const location = useLocation(); 
	const [url, setUrl] = useState(null);
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(true)
	const auth = getAuth()
	
	useEffect(()=>{
		if(auth?.currentUser){
			setUser(auth.currentUser)
		}else{
			setUser(null)
		}
		setLoading(false)
		setUrl(location.pathname);
	}, [user, auth.currentUser, location])
	





  return (
	<>
	{loading ? (<Spinner/> ): (<>
    <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
	    <div className="container">
	      {/* <a className="navbar-brand" href="index.html">GreenGold</a> */}
		  <Link className="navbar-brand" to='/'>GreenGold</Link>
	      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
	        <span className="oi oi-menu"></span> Menu
	      </button>

	      <div className="collapse navbar-collapse" id="ftco-nav">
	        <ul className="navbar-nav ml-auto">
	          <li className={"nav-item underline" + (url === "/" ?" active" : "")}><Link to='/' className='nav-link'>Home</Link></li>
	          <li className={"nav-item underline" + (url === "/products" ?" active" : "")}><Link to='/products' className='nav-link'>Products</Link></li>
	          <li className={"nav-item underline" + (url === "/about" ?" active" : "")}><Link to='/about' className='nav-link'>About</Link></li>
			  <li className={"nav-item underline" + (url === "/gallery" ?" active" : "")}><Link to='/gallery' className='nav-link'>Gallery</Link></li>
	          <li className={"nav-item underline" + (url === "/news" ?" active" : "")}><Link to='/news' className='nav-link'>News</Link></li>
	          <li className={"nav-item underline" + (url === "/contact" ?" active" : "")}><Link to='/contact' className='nav-link'>Contact</Link></li>
			  {user && <li className={"nav-item underline" + (url === "/admin" ?" active" : "")}><Link to='/admin' className='nav-link'>Admin</Link></li>}
			 {/* <li className={"nav-item underline" + (url === "/admin" ?" active" : "")}><Link to='/admin' className='nav-link'>Admin</Link></li> */}
				

			  
	        </ul>
	      </div>
	    </div>
	  </nav></>)}
	  </>
  )
}

export default Navbar