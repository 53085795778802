import React from 'react'
import AboutBanner from '../../components/about-banner/AboutBanner'
import AboutSection from '../../components/about-section/AboutSection'
import AboutCover from '../../components/about-cover/AboutCover';
import Newsletter from "../../components/newsletter/newsletter";
import AboutHero from '../../components/about-hero/AboutHero';
function About() {
   
  return (
    <>
    <AboutBanner pageName={"About Us"}/>
    <AboutSection/>
    <Newsletter/>
    {/* <AboutCover/> */}
    <AboutHero/>
    </>
  )
}

export default About