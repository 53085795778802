import React from 'react'
import Hero from "../../components/hero/hero";
import Products from "../../components/products/products";
import Newsletter from "../../components/newsletter/newsletter";
import Slider from "../../components/slider/slider";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
function Home() {
  return (
    <>

        <Slider/>
     
      {/* <AnimationOnScroll  animateOnce={true} animateIn="animate__tada"> */}
        <Hero/>
      {/* </AnimationOnScroll> */}
      {/* <AnimationOnScroll  animateOnce={true} animateIn="animate__tada"> */}
        <Products/>
      {/* </AnimationOnScroll> */}
      {/* <AnimationOnScroll  animateOnce={true} animateIn="animate__tada">
        <Banner/>
      // </AnimationOnScroll> */}
      {/* <AnimationOnScroll   animateOnce={true} animateIn="animate__tada"> */}
        <Newsletter/>
      {/* </AnimationOnScroll> */}
      </>
  )
}

export default Home