import { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase.config'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from '../../components/Spinner/Spinner';


import NewsTable from '../../pages/Admin/NewsTable'
function AddNewModal() {

  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false)
  const [flag, setflag] = useState(true)
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    images: {},
  })

  const {
    title,
    body,
    images
  } = formData

  const auth = getAuth()
  const navigate = useNavigate()
  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ ...formData, userRef: user.uid })
        } else {
          navigate('/login')
        }
      })
    }

    return () => {
      isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])


  const onSubmit = async (e) => {
    console.log("Adding...")
    e.preventDefault()
    setLoading(true)
    if(title === '' || body === '' || Object.keys(images).length === 0){
      toast.error("Please Fill All The Form", {
        position: toast.POSITION.TOP_LEFT
      });
    }

    // Store image in firebase
    const storeImage = async (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage()
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`

        const storageRef = ref(storage, 'images/' + fileName)

        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
              default:
                break
            }
            //handleClose()
          },
          (error) => {
            reject(error)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL)
            })
          }
        )
      })
    }


    const imgsUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch(() => {
      setLoading(false)
      toast.error("Error Occured !", {
        position: toast.POSITION.TOP_LEFT
      });
      return
    })
    const formDataCopy = {
      ...formData,
      imgsUrls,
      imageURL: imgsUrls[0],
      timestamp: serverTimestamp(),
    }
    delete formDataCopy.images
    const docRef = await addDoc(collection(db, 'news'), formDataCopy)
    
    setLoading(false)
    
    toast.success("News Saved !", {
      position: toast.POSITION.TOP_RIGHT
    });
    setFormData({  
      title: '',
      body: '',
      images: {},
    })
    handleClose()
    setflag(!flag)
  }

  const onMutate = (e) => {
   
    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        images: e.target.files,
      }))
    }

     // Text/Booleans/Numbers
     if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }))
    }


  }
  return (
    <>
     {loading ? (<Spinner/> ): (<>
    <ToastContainer />
        <Button variant="primary" className='mb-3' onClick={handleShow}>
        Add New
      </Button>

      <NewsTable flag={flag}/>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form >
        <Form.Group className="mb-3" >
            <Form.Label>Title</Form.Label>
            <Form.Control type='text'
                id='title'
                value={title}
                onChange={onMutate}
                required placeholder="Title" />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Body</Form.Label>
            <Form.Control as="textarea" rows={3} id='body'
                value={body}
                onChange={onMutate}
                min='1'
                
                required/>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <Form.Control type="file"  id='imageURL'
            onChange={onMutate}
            accept='.jpg,.png,.jpeg'
            multiple
            required/>
          </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type='submit' onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> </>)}
    </>
  )
}

export default AddNewModal