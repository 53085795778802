import React from 'react'
function GalleryItem({picture}) {
    var sectionStyle = {
        backgroundImage: "url(" + picture?.imageURL + ")"
     };
  return (
    <div className='col-md-6 col-lg-4'>
                    <div className="category-wrap img d-flex align-items-end"  style={sectionStyle}>
							<div className="text px-3 py-1">
								<h2 className="mb-0">{picture?.name}</h2>
							</div>
						</div>
    </div>
  )
}

export default GalleryItem