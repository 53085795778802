import React from 'react'
import AboutBanner from '../../components/about-banner/AboutBanner'
import NewsSection from '../../components/news-section/NewsSection'
function News() {
  return (
    <>
    <AboutBanner pageName={"News"}/>
    <NewsSection/>
    </>
  )
}

export default News