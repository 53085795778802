import React from 'react'
import Background from '../../images/bgg-2.png';
import Background2 from '../../images/bgg-3.png';

import "@fontsource/amatic-sc";
function Slider() {
  
     var secHeight = {
        height: "700px"
     };

  return (
    <>
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100"  style={secHeight} src={Background} alt="First slide"/>
      <div className="carousel-caption d-none d-md-block">

  </div>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" style={secHeight} src={Background2} alt="Second slide"/>
      <div className="carousel-caption d-none d-md-block">

  </div>
    </div>

  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
    </>
  );
}


export default Slider