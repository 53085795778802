import React, {useState, useEffect} from 'react'
import {collection, getDocs, query} from 'firebase/firestore'
import {db} from '../../firebase.config'

import New from './New';
import Spinner from '../../components/Spinner/Spinner';
function NewsSection() {


	const [news, setNews] = useState(null)
    const [loading, setLoading] = useState(true)

	 useEffect(()=>{
		const fetchNews = async () =>{
			try {
				const newsRef = collection(db, 'news')
				const q = query(newsRef)

				const querySnap = await getDocs(q)
				let news = []
				querySnap.forEach((doc)=>{
				   return news.push({
					   id: doc.id,
					   data: doc.data()
				   })
				})
				setNews(news)
				setLoading(false)
			} catch (error) {
				console.log(error)
			}
		}
		fetchNews()
	},[]) 



  return (
    <>
    <section className="ftco-section ftco-degree-bg">
      <div className="container">
        <div className="row">
        <div className="row">
			
		{loading ? (<Spinner/> ): news && news.length > 0 ?
					 (<>
						{news.map((single)=>(
							<New key={single.id} single={single.data} uid={single.id}/>
						))}
					 </>)
					: (<p>No products to show</p>)
		}
				
			</div>
        </div>
    </div>
    </section>
    </>

  )
}

export default NewsSection