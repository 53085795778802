import React from 'react'
import { Link } from 'react-router-dom'
function Product({product, uid}) {

  return (
	<div className="col-md-6 col-lg-3">
    <div className="product">
						<img className="img-fluid" src={product.imageURL} style={{"height" : "200px", "width" : "100%"}} alt="Colorlib Template"/>
    						<div className="overlay"></div>
    					
    					<div className="text py-3 pb-4 px-3 text-center">
    						<h3>{product.name}</h3>
	    					<div className="bottom-area d-flex px-3">
	    						<div className="m-auto d-flex">
								<Link 
									to={`/products/${uid}`}
									className='add-to-cart d-flex justify-content-center align-items-center text-center'
								>
									<span><i className="ion-ios-menu"></i></span>
								</Link>
	    							{/* <a href="#" className="add-to-cart d-flex justify-content-center align-items-center text-center">
	    								<span><i className="ion-ios-menu"></i></span>
	    							</a>
	    							<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
	    								<span><i className="ion-ios-cart"></i></span>
	    							</a>
	    							<a href="#" className="heart d-flex justify-content-center align-items-center ">
	    								<span><i className="ion-ios-heart"></i></span>
	    							</a> */}
    							</div>
    						</div>
    					</div>
    				</div>
					</div>
  )
}

export default Product