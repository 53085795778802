import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { getDoc,  doc} from 'firebase/firestore'
import {db} from '../../firebase.config'
import Spinner from '../Spinner/Spinner';

function ProductDetail() {
	const auth = getAuth()
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(true)
	const navigate = useNavigate()
	const params = useParams()
	useEffect(() => {
    const fetchProduct = async () => {
      const docRef = doc(db, 'products', params.id)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        setProduct(docSnap.data())
        setLoading(false)
      }
    }

    fetchProduct()
  }, [navigate, params.id])
  if (loading) {
    return <Spinner />
  }

  return (
    <section className="ftco-section">
    	<div className="container">
    		<div className="row">
    			<div className="col-lg-6 mb-5">
    				<a href="images/product-1.jpg" className="image-popup"><img src={product?.imageURL} className="img-fluid" alt=""/></a>
    			</div>
    			<div className="col-lg-6 product-details pl-md-5">
    				<h3>{product?.name}</h3>
    				<p>{product?.about}</p>
    			</div>
    		</div>
    	</div>
    </section>
  )
}

export default ProductDetail