import React, {useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAuth } from 'firebase/auth'
import {collection, getDocs, query, deleteDoc,  doc} from 'firebase/firestore'
import {db} from '../../firebase.config'
import MessagesModal from '../../modals/MessagesModal/MessagesModal';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from '../../components/Spinner/Spinner';
function MessageTable() {
    const auth = getAuth()
    const [messages, setMessages] = useState(null)
    const [loading, setLoading] = useState(true)

    const onDelete = async (msgId) => {
        if (window.confirm('Are you sure you want to delete?')) {
          await deleteDoc(doc(db, 'messages', msgId))
          const updatedMessages = messages.filter(
            (msg) => msg.id !== msgId
          )
          setMessages(updatedMessages)
          toast.success("Message Deleted!", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }

    
      useEffect(()=>{
		const fetchMsgs = async () =>{
			try {
				const newsRef = collection(db, 'messages')
				const q = query(newsRef)

				const querySnap = await getDocs(q)
				let msgs = []
				querySnap.forEach((doc)=>{
				   return msgs.push({
					   id: doc.id,
					   data: doc.data()
				   })
				})
				setMessages(msgs)
				setLoading(false)
			} catch (error) {
				console.log(error)
			}
		}
		fetchMsgs()
	},[]) 

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
  return (
    <>
    {loading ? (<Spinner/> ): messages && messages.length > 0 ?
					 (<>
            <ToastContainer />
						<TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Subject</StyledTableCell>
            <StyledTableCell align="right">View</StyledTableCell>
            <StyledTableCell align="right">Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map((msg) => (
            <StyledTableRow key={msg.id}>
              <StyledTableCell component="th" scope="row">
                {msg.data.name}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {msg.data.subject}
              </StyledTableCell>
              <StyledTableCell align="right">
                <MessagesModal message={msg.data}/>
	    							</StyledTableCell>
              <StyledTableCell align="right">
                <button className='btn btn-danger' onClick={() => onDelete(msg.id)}>Delete</button>
	    							</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
					 </>)
					: (<p>No products to show</p>)
					}
     
    </>
  )
}

export default MessageTable