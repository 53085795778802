import React, { useState } from 'react'
import { db, firebaseConfig} from '../../firebase.config'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { initializeApp } from "firebase/app";
import Spinner from '../../components/Spinner/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
function Login() {

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
      email: '',
      password: '',
    })
    const { email, password } = formData
  
    const navigate = useNavigate()
  
    const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }))
    }
  
    const onSubmit = async (e) => {
      e.preventDefault()
        setLoading(true)
        console.log("Hello")
      try {
        console.log("Hello")
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);
  
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        )
        console.log(userCredential.user)
  
        if (userCredential.user) {
          setLoading(false)
          navigate('/')
          
        }
      } catch (error) {
        console.log(error)
      }
    }
  return (
    <>
    {loading ? (<Spinner/> ): (<>
    <div className="ftco-section">
    <div className='container'>
      <h2>Log In</h2>
    <div className='row mb-3'>
    <Form onSubmit={onSubmit}>
    <Form.Group className="mb-3" >
        <Form.Label>Email</Form.Label>
        <Form.Control type='email'
            id='email'
            value={email}
            onChange={onChange}
            required placeholder="Email" />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password"  id='password'  placeholder="Password"
        onChange={onChange}
        required/>
      </Form.Group>
      <Button type='submit' >Log In</Button>
      </Form>
    {/* <form onSubmit={onSubmit}>
      <label>
        <p>Username</p>
        <input
            type='email'
            placeholder='Email'
            id='email'
            value={email}
            onChange={onChange}/>
      </label>
      <label>
        <p>Password</p>
        <input
              type='password'
              placeholder='Password'
              id='password'
              value={password}
              onChange={onChange}/>
      </label>
      <button type='submit'>Submit</button>
    </form> */}
    </div>
    </div>
     </div>  </>)}
     </>
    
  )
}

export default Login