import React from 'react'
import AboutBanner from '../../components/about-banner/AboutBanner'
import Shop from '../../components/shop/Shop'
function Products() {
  return (
    <>
    <AboutBanner pageName={"Products"}/>
    <Shop/>
    </>
  )
}

export default Products