import React, { useState } from 'react'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase.config'
import { getAuth } from 'firebase/auth'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
function Newsletter() {

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
  })
  const {email} = formData

  const onSubmit = async (e) => {
    console.log("Adding...")
    e.preventDefault()
    setLoading(true)

    
    const formDataCopy = {
      ...formData,
      timestamp: serverTimestamp(),
    }
    const docRef = await addDoc(collection(db, 'emails'), formDataCopy)
    
    setLoading(false)
    setFormData({
      email: '',
    })
    toast.success("Subcribed!", {
      position: toast.POSITION.TOP_RIGHT
    });
  }


  const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }))
  }

  return (
    <>
    <ToastContainer />
    <section className="ftco-section ftco-no-pt ftco-no-pb py-5 bg-light">
        <div className="container py-4">
        <div className="row d-flex justify-content-center py-5">
            <div className="col-md-6">
                <h2  className="mb-0">Subcribe to our Newsletter</h2>
                <span>Get e-mail updates about our latest shops and special offers</span>
            </div>
            <div className="col-md-6 d-flex align-items-center">
            <form action="#" className="subscribe-form">
            <div className="form-group d-flex">
                <input type="text" value={email} onChange={onChange} required id="email" className="form-control" placeholder="Enter email address"/>
                <input type="submit" onClick={onSubmit} value="Subscribe" className="submit px-3"/>
            </div>
            </form>
          </div>
        </div>
        </div>
    </section>
    </>
  )
}

export default Newsletter