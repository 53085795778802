import React from 'react'
import {Link} from 'react-router-dom'
function footer() {
  return (
    <footer className="ftco-footer ftco-section">
      <div className="container">
      	<div className="row">
      		<div className="mouse">
						<a href="#" className="mouse-icon">
							<div className="mouse-wheel"><span className="ion-ios-arrow-up"></span></div>
						</a>
					</div>
      	</div>
        <div className="row mb-5">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Green Gold</h2>
             
              {/* <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                <li className="ftco-animate"><a href="#"><span className="icon-twitter"></span></a></li>
                <li className="ftco-animate"><a href="#"><span className="icon-facebook"></span></a></li>
                <li className="ftco-animate"><a href="#"><span className="icon-instagram"></span></a></li>
              </ul> */}
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2">Menu</h2>
              <ul className="list-unstyled">
                <li><Link to='/products' className='nav-link'>Products</Link></li>
                <li><Link to='/about' className='nav-link'>About</Link></li>
                <li><Link to='/news' className='nav-link'>News</Link></li>
                <li><Link to='/contact' className='nav-link'>Contact Us</Link></li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-4">
             <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Help</h2>
              <div className="d-flex">
	              <ul className="list-unstyled mr-l-5 pr-l-3 mr-4">
	                <li><a href="#" className="py-2 d-block">Shipping Information</a></li>
	                <li><a href="#" className="py-2 d-block">Returns &amp; Exchange</a></li>
	                <li><a href="#" className="py-2 d-block">Terms &amp; Conditions</a></li>
	                <li><a href="#" className="py-2 d-block">Privacy Policy</a></li>
	              </ul>
	              <ul className="list-unstyled">
	                <li><a href="#" className="py-2 d-block">FAQs</a></li>
	                <li><a href="#" className="py-2 d-block">Contact</a></li>
	              </ul>
	            </div>
            </div>
          </div> */}
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
            	<h2 className="ftco-heading-2">Have a Questions?</h2>
            	<div className="block-23 mb-3">
	              <ul>
	                <li><span className="icon icon-map-marker"></span><span className="text"> Next to AbdelGhabby School, El selelieen, El Fayoom Egypt</span></li>
	                <li><span className="icon icon-phone"></span><span className="text">+201000467679</span></li>
	                <li><span className="icon icon-envelope"></span><span className="text">greengold.live@outlook.com</span></li>
	              </ul>
	            </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">

            <p>
						  Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This website is made with <i className="icon-heart color-danger" aria-hidden="true"></i> by Omar A. Lebda
						 
			</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer