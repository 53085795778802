import React from 'react'
import aboutPic from '../../images/olives-about.jpg';


function AboutSection() {
    var sectionStyle = {
        backgroundImage: "url(" + aboutPic + ")"
     };

  return (
    <section className="ftco-section ftco-no-pb ftco-no-pt bg-light">
    <div className="container">
        <div className="row">
            <div className="col-md-5 p-md-5 img img-2 d-flex justify-content-center align-items-center" style={sectionStyle}>
             
            </div>
            <div className="col-md-7 py-5 wrap-about pb-md-5">
      <div className="heading-section-bold mb-4 mt-md-5">
          <div className="ml-md-0">
            <h2 className="mb-4">Welcome to Green Gold Website</h2>
        </div>
      </div>
      <div className="pb-md-5">
          <p>With 33 years of experience in olive cultivation and export, our company has
established itself as leading provider of premium quality olives to customers
worldwide. Our expertise and commitment to quality have earned us a reputation
for excellence, and we take pride in delivering the best in olive products to our
customers. Experience the difference of working with a trusted and experienced
partner in the world of olives</p>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default AboutSection