import React, {useState, useEffect} from 'react'

import {  useNavigate, useParams } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { getDoc,  doc} from 'firebase/firestore'
import {db} from '../../firebase.config'
import Spinner from '../Spinner/Spinner';

function NewsDetail() {
  const auth = getAuth()
    const [news, setNews] = useState(null)
    const [loading, setLoading] = useState(true)
	const navigate = useNavigate()
	const params = useParams()
	useEffect(() => {
    const fetchNews = async () => {
      const docRef = doc(db, 'news', params.id)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        setNews(docSnap.data())
        setLoading(false)
      }
    }

    fetchNews()
  }, [navigate, params.id])
  if (loading) {
    return <Spinner />
  }
  return (
    <>
    {/* <section className="ftco-section ftco-degree-bg">
        <div className="container">
            <div className="row">
            <h2 className="mb-3">{news.title}</h2>
            </div>
            <div className="row">
              <img src={news.imageURL} alt="" style={{"height" : "400px", "width" : "400px"}} class="img-fluid"/>
            </div>
            <div className="row">

            <p>{news.body}</p>
            </div>
            
        </div>
    </section> */}


    <section className="ftco-section">
    	<div className="container">
    		<div className="row">
    			<div className="col-lg-6 mb-5">
    				<a href="images/product-1.jpg" className="image-popup"><img src={news?.imageURL} className="img-fluid" alt=""/></a>
    			</div>
    			<div className="col-lg-6 product-details pl-md-5">
    				<h3>{news?.title}</h3>
    				<p>{news?.body}</p>
    			</div>
    		</div>
    	</div>
    </section>
    </>
  )
}

export default NewsDetail