import React from 'react'
import {Link} from 'react-router-dom'
import Background from '../../images/banner.jpg';
function AboutBanner({pageName}) {
  var sectionStyle = {
    backgroundImage: "url(" + Background + ")"
 };
  return (
    <div className="hero-wrap hero-bread" style={sectionStyle}>
      <div className="container">
        <div className="row no-gutters slider-text align-items-center justify-content-center">
          <div className="col-md-9 text-center">
          	<p className="breadcrumbs"><span className="mr-2"><Link to='/'>Home</Link></span> <span>{pageName}</span></p>
            <h1 className="mb-0 bread">{pageName}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutBanner