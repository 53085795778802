import React, {useState, useEffect} from 'react'
import Background from '../../images/product-1.jpg';
import {collection, getDocs, query} from 'firebase/firestore'
import {db} from '../../firebase.config'
import Product from './Product';
import Spinner from '../../components/Spinner/Spinner';
function Shop({isHomePage}) {


	 const [products, setProducts] = useState(null)
	 const [loading, setLoading] = useState(true)
	 
	 const [productsNum, setProductsNum] = useState(null)
 
 
	 useEffect(()=>{
		 const fetchProducts = async () =>{
			 try {
				 const productsRef = collection(db, 'products')
				 const q = query(productsRef
					)

				 const querySnap = await getDocs(q)
				 let products = []
				 querySnap.forEach((doc)=>{
					return products.push({
						id: doc.id,
						data: doc.data()
					})
				 })
				 setProducts(products)
				 if(isHomePage){
					if(products.length > 8){
						setProductsNum(8)
					}else{
						setProductsNum(products.length)
					}
				 }else{
					setProductsNum(products.length)
				 }
				 setLoading(false)
			 } catch (error) {
				 console.log(error)
			 }
		 }
		 fetchProducts()

	 },[]) 
  return (
    <section className="ftco-section">
    	<div className="container">
    		<div className="row">
    			
					{loading ? (<Spinner/> ): products && products.length > 0 ?
					 (<>
						{products.slice(0, productsNum).map((product)=>(
							<Product key={product.id} product={product.data} uid={product.id}/>
						))}
					 </>)
					: (<p>No products to show</p>)
					}
    				
    		</div>
    	</div>
    </section>
  )
}

export default Shop