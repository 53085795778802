import React, { useState } from 'react'
import AboutBanner from '../../components/about-banner/AboutBanner'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase.config'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
function Contact() {

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  })

  const {
    name,
    email,
    subject,
    message
  } = formData

  const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }))

  }


  const onSubmit = async (e) => {
    console.log("Adding...")
    if(name === '' || email === '' || subject === '' || message === ''){
      toast.error("Please Fill All The Form", {
        position: toast.POSITION.TOP_LEFT
      });
    }
    e.preventDefault()
    setLoading(true)

    const formDataCopy = {
      ...formData,
      timestamp: serverTimestamp(),
    }
    const docRef = await addDoc(collection(db, 'messages'), formDataCopy)
    
    setLoading(false)
    console.log('Message saved')
    toast.success("Message Sent!", {
      position: toast.POSITION.TOP_RIGHT
    });
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    })
  }

  return (
    <>
    <ToastContainer />
    <AboutBanner pageName={"Contact"}/>
    <section className="ftco-section contact-section bg-light">
    <div className="container">
        <div className="row d-flex mb-5 contact-info">
        <div className="w-100"></div>
        <div className="col-md-3 d-flex">
            <div className="info bg-white p-4">
              <p><span>Address:</span> Next to AbdelGhabby School, El selelieen, El Fayoom Egypt</p>
            </div>
        </div>
        <div className="col-md-3 d-flex">
            <div className="info bg-white p-4">
              <p><span>Phone:</span> +201022027555</p>
              <p><span>Phone:</span> +201000467679</p>
              <p><span>Phone:</span> +201226539993</p>
              <p><span>Phone:</span> +20482630134</p>

            </div>
        </div>
        <div className="col-md-3 d-flex">
            <div className="info bg-white p-4">
              <p><span>Email:</span> greengold.live@outlook.com</p>
            </div>
        </div>
        <div className="col-md-3 d-flex">
            <div className="info bg-white p-4">
              <p><span>Website:</span> Greengold.live</p>
            </div>
        </div>
      </div>
      <div className="row block-9">
        <div className="col-md-6 order-md-last d-flex">

          <Form className="bg-white p-5 contact-form">
        <Form.Group className='mb-3'>
            <Form.Control type='text'
                id='name'
                value={name}
                onChange={onChange}
                required placeholder="Your Name" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Control type='email'
                id='email'
                value={email}
                onChange={onChange}
                required placeholder="Your Email" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Control type='text'
                id='subject'
                value={subject}
                onChange={onChange}
                required placeholder="Subject" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Control as="textarea" rows={7} cols={30} id='message'
                value={message}
                onChange={onChange}
                min='1'
                max='50'
                required/>
          </Form.Group>
          <Button variant="primary" type='submit' onClick={onSubmit} >
            Send
          </Button>
          </Form>
        
        </div>

        <div className="col-md-6 d-flex">
            <div id="map" className="bg-white"></div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Contact