import React from 'react'


function hero() {

  return (
    
	<section className="ftco-section">
		<div className="container">
			<div className="row no-gutters ftco-services">
				<div className="col-md-3 text-center d-flex align-self-stretch">
				<div className="media block-6 services mb-md-0 mb-4">
              <div className="icon bg-color-1 active d-flex justify-content-center align-items-center mb-2">
            		<span className="flaticon-shipped"></span>
              </div>
              <div className="media-body">
                <h3 className="heading">Shipping</h3>
                <span>Shipping all over the world</span>
              </div>
            </div>
					
				</div>


				<div className="col-md-3 text-center d-flex align-self-stretch ">
            <div className="media block-6 services mb-md-0 mb-4">
              <div className="icon bg-color-2 d-flex justify-content-center align-items-center mb-2">
            		<span className="flaticon-diet"></span>
              </div>
              <div className="media-body">
                <h3 className="heading">Always Fresh</h3>
                <span>Product well package</span>
              </div>
            </div>    
          </div>
          <div className="col-md-3 text-center d-flex align-self-stretch ">
            <div className="media block-6 services mb-md-0 mb-4">
              <div className="icon bg-color-3 d-flex justify-content-center align-items-center mb-2">
            		<span className="flaticon-award"></span>
              </div>
              <div className="media-body">
                <h3 className="heading">Superior Quality</h3>
                <span>Quality Products</span>
              </div>
            </div>      
          </div>
          <div className="col-md-3 text-center d-flex align-self-stretch">
            <div className="media block-6 services mb-md-0 mb-4">
              <div className="icon bg-color-4 d-flex justify-content-center align-items-center mb-2">
            		<span className="flaticon-customer-service"></span>
              </div>
              <div className="media-body">
                <h3 className="heading">Support</h3>
                <span>24/7 Support</span>
              </div>
            </div>      
          </div>
			</div>
		</div>
	</section>

    

  )
}

export default hero