import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
function New({single, uid}) {
    
    var sectionStyle = {
        backgroundImage: "url(" + single.imageURL + ")"
     };

	 const [date, setDate] = useState('')

	 useEffect(()=>{
		const dateObject = new Date((single.timestamp['seconds'] * 1000) + (single.timestamp['nanoseconds']/1000))
		const humanDateFormat = dateObject.toLocaleString()
		setDate(humanDateFormat)
	 }, []) 
  return (
    <div className="col-md-12 d-flex">
		            <div className="blog-entry align-self-stretch d-md-flex">
		              <a href="blog-single.html" className="block-20" style={sectionStyle}>
                        {/* <img src={single.imageURL} alt=""/> */}
		              </a>
		              <div className="text d-block pl-md-4">
		              	<div className="meta mb-3">
		                  <div>{date}</div>
		                  <div>Admin</div>
		                </div>
		                <h3 className="heading">{single.title}</h3>
		                <p>{single.body}</p>
		                {/* <p><a href="blog-single.html" className="btn btn-primary py-2 px-3">Read more</a>

						</p> */}
						<Link 
									to={`/news/${uid}`}
									className="btn btn-primary py-2 px-3"
							>
									Read more
								</Link>
		              </div>
		            </div>
		          </div>
  )
}

export default New