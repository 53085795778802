import React from 'react'
import AboutBanner from '../../components/about-banner/AboutBanner'
import NewsDetail from '../../components/new-detail/NewsDetail'
function SingleNew() {
  return (
   <>
   <AboutBanner pageName={"New"}/>
   <NewsDetail/>
   </>
  )
}

export default SingleNew